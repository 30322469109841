import React, { useContext } from 'react';
import { useRouter } from 'next/router';
import Lottie from 'lottie-react';
import { useDispatch, useSelector } from 'react-redux';
import buildConfig from 'utils/buildConfig';
import mixpanel, { trackedEvents } from 'utils/mixpanel';
import Button from 'seedly-component-library/button-new';
import { RootState } from 'reducer';
import {
  closeSignUpCompleteModal,
  openRewardLaunchModal,
} from 'dispatcher/modalDispatcher';
import { CommunityPageContext } from 'pageContents/CommunityContent/context/CommunityPageContext';
import Dialog from 'seedly-component-library/dialog';
import rocketAnimation from './rocket.json';

const SignUpCompleteModal = () => {
  const dispatch = useDispatch();

  const { setPageType } = useContext(CommunityPageContext);

  const isSignUpCompleteModalOpen = useSelector(
    (props: RootState) => props.modal.isSignUpCompleteModalOpen,
  );
  const router = useRouter();

  const handleContinueClick = () => {
    mixpanel.track(trackedEvents.LoginSignUpModal, {
      param: 'continue_where_i_left_off',
    });
    sessionStorage.removeItem('user-signedup');
    dispatch(closeSignUpCompleteModal());

    if (sessionStorage.getItem('daily-checkin') === 'true') {
      dispatch(openRewardLaunchModal());
    }
  };

  const handleDiscoverClick = () => {
    mixpanel.track(trackedEvents.LoginSignUpModal, {
      param: 'discover_community',
    });
    sessionStorage.removeItem('user-signedup');
    router.push(`${buildConfig.apiHost}/community`);
    setPageType('home');
    dispatch(closeSignUpCompleteModal());
  };

  return (
    <Dialog
      isOpen={isSignUpCompleteModalOpen}
      onCloseClick={() => {
        mixpanel.track(trackedEvents.LoginSignUpModal, {
          param: 'close_explore',
        });
        sessionStorage.removeItem('daily-checkin');
        dispatch(closeSignUpCompleteModal());
      }}
      widthSize="sm"
      data-testId="signup-complete-modal"
    >
      <div className="flex flex-col gap-8 items-center">
        <Lottie
          animationData={rocketAnimation}
          loop
          autoplay
          style={{ height: 160, width: 160 }}
        />
        <div className="flex flex-col gap-2 items-center">
          <p className="text-xl font-bold">Yay, you’re ready to explore!</p>
          <p className="text-neutral-500 text-center">
            Learn about personal finance through community discussions, articles
            and product reviews.
          </p>
        </div>
        <div className="flex flex-col lg:flex-row w-full items-center">
          <Button onClick={handleContinueClick} isBlock>
            Continue where I left off
          </Button>
          <Button
            className="mt-2 lg:mt-0 ml-0 lg:ml-2"
            variant="outline"
            onClick={handleDiscoverClick}
            isBlock
          >
            Discover Community
          </Button>
        </div>
      </div>
    </Dialog>
  );
};

export default SignUpCompleteModal;
